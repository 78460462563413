<template>
    <div>
        <div class="row">
            <div class="col-sm-6">
                <CSMap
                    :zoom="data.zoom"
                    :center="coordinates"
                    :marker="markers"
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution:='&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                />
            </div>
            <div class="col-sm-6">
                <div class="cs-action-head">
                    <h2 class="cs-title" v-if="data.title">
                        <ColorSplit :data="data.title" />
                    </h2> 
                    <h3 class="cs-sub-title" v-if="data.subTitle">{{ data.subTitle }}</h3>
                    <span class="cs-text" v-if="data.text" v-html="data.text"></span>
                    <div class="cs-fu-tags">
                        <div v-for="(tag, i) in data.findUsTags" class="cs-fu-tag">
                            <label>
                                <input type="radio" :checked="selectedTag === `${tag.slug}`" :value="tag.slug" @change="selectedTag = $event.target.value"> {{tag.tagLabel}}
                            </label>
                        </div>
                    </div>
                    <div>
                        <Buttons :data="data.buttons" :cs_style="data.style" />
                    </div>
                </div> 
            </div>
        </div>
        <template v-if="fullSelectedTag">
            <h3 class="tag-title" v-if="fullSelectedTag">
                <ColorSplit :data="fullSelectedTag.tagLabel" />
            </h3>

            <div class="row">
                <template v-for="(st, sti) in selectedTags" >
                    <template v-if="fullSelectedTag.largeCards">
                        <div :key="`tag-${sti}`" class="col-12">
                            <div class="fu-large fu-card">
                                <div class="card-front" v-if="sti != openContacts">
                                    <div class="fu-image">
                                        <img :src="st.image" :alt="st.title">
                                    </div>
                                    <div class="text-area">
                                        <div class="cs-image-container cs-image-ratio-16x9">
                                            <img :src="st.logo" :alt="st.title">
                                        </div>
                                        <h4>
                                            <ColorSplit :data="st.title" />
                                        </h4>
                                        <span class="fu-text" v-html="st.text"></span>
                                        <div v-html="st.address"></div>

                                        <span class="contact-info" v-if="st.email">
                                            <CustomIcon type="email" />{{st.email}}
                                        </span>
                                        <span class="contact-info" v-if="st.fax">
                                            <CustomIcon type="fax" />{{st.fax}}
                                        </span>
                                        <span class="contact-info" v-if="st.tel">
                                            <CustomIcon type="phone" />{{st.tel}}
                                        </span>
                                        <div class="open-contact-buttons">  
                                            <Buttons :data="showContactButton" :payload="`${sti}`" />  
                                        </div>  
                                    </div>
                                </div>
                                <div class="card-back" v-else>
                                    <div class="row">
                                        <div class="col-sm-6 col-lg-4 col-xl-3" v-for="(contact, contactIndex) in st.contacts">
                                            <ContactCards :data="contact" />
                                        </div>
                                    </div>
                                    <div class="open-contact-buttons">
                                        <Buttons :data="showContactButton" :payload="`${sti}`" />  
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div :key="`tag-${sti}`" class="col-sm-6 col-lg-4 col-xl-4">
                            <div class="fu-small fu-card">
                                <div class="cs-image-container cs-image-ratio-16x9">
                                    <img :src="st.image" :alt="st.title">
                                </div>
                                <h4>
                                    <ColorSplit :data="st.title" />
                                </h4>
                                <span class="fu-text" v-html="st.text"></span>
                                <div v-html="st.address"></div>

                                <span class="contact-info" v-if="st.email">
                                    <CustomIcon type="email" />{{st.email}}
                                </span>
                                <span class="contact-info" v-if="st.fax">
                                    <CustomIcon type="fax" />{{st.fax}}
                                </span>
                                <span class="contact-info" v-if="st.tel">
                                    <CustomIcon type="phone" />{{st.tel}}
                                </span>
                                <div class="open-contact-buttons">  
                                    <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#contact-modal" @click="openContactsFN(sti, true)">
                                        {{data.showContactsLabel}}
                                    </button>
                                </div>  
                            </div>
                        </div>
                    </template>
                </template>
            </div>
        </template>

        <div class="modal fade" id="contact-modal" tabindex="-1" role="dialog" aria-labelledby="contact-modal" aria-hidden="true">
            <div class="modal-dialog modal-xl" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-sm-6 col-lg-4 col-xl-3" v-for="(contact, contactIndex) in selectedContact">
                                <ContactCards :data="contact" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'CSFindUs',
    components: {
        Buttons: () => import("../components/CSUtilsButtons.vue"),
        ColorSplit: () => import("../components/CSUtilsColorSplit.vue"),
        ContactCards: () => import("../components/CSUtilsContactCards.vue"),
        CustomIcon: () => import("../components/CSUtilsCustomIcon.vue"),
        CSMap: () => import('../components/CSMap.vue'),
    },
    data() {
        return {
            selectedTag:'',
            openContacts:'none'
        };
    },
    props: {
        data: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        showContactButton(){
            return [{
                buttonText:this.data.showContactsLabel,
                action:this.openContactsFN
            }]
        },
        coordinates() {
            return [this.data.markerLat, this.data.markerLon]
        },
        locations(){
            console.log(this.data.locations)
            if(this.data.locations) return Object.values(this.data.locations);
            else return []  
        },
        findUsTags(){
            if(this.data.findUsTags) return Object.values(this.data.findUsTags);
            else return []
        }, 
        selectedTags(){
            let selectedTags = []
            
            for (var i = 0; i < this.locations.length; i++) {
                for (var j = 0; j < this.locations[i].tags.length; j++) {
            
                    if(this.locations[i].tags[j].slug == `findUs-${this.selectedTag}`) {
                        selectedTags.push(this.locations[i])
                        break;
                    }
                }
            }
            
            return selectedTags
        },
        fullSelectedTag(){
            if(this.selectedTag){
                let fst;
                for (var i = 0; i < this.findUsTags.length; i++) {

                    if(this.selectedTag == this.findUsTags[i].slug) fst = this.findUsTags[i]
                }
                return fst   
            }else return false
        },
        selectedContact(){
            if(this.openContacts != 'none'){
                return this.selectedTags[this.openContacts].contacts
            }
            return []
        },
        markers() {
            let result = [
                {
                    isUser: true,
                    link: '#',
                    marker: this.coordinates
                }
            ];

            if (this.data.locations && Array.isArray(this.data.locations) && this.data.locations.length) {
                this.data.locations.forEach((location, idx) => {
                    result.push({
                        link: `find-us-${idx}`,
                        marker: [location.latitude, location.longitude]
                    })
                });
            }

            return result;
        }
    },
    methods: {
        openContactsFN(index, type){
            if(this.openContacts != index) this.openContacts = index;
            else if(!type) this.openContacts = 'none'
        }
    },
    mounted() {
        this.selectedTag = this.findUsTags[0].slug
    },
    watch: {
        selectedTag(o,n){
            console.log(o,n)
        }
    },
};
</script>

<style lang="scss" scoped>
    h3.tag-title {
        margin: 40px 0;
    }

    span.contact-info {
        padding: 5px 10px;
        background: #e1f2fe;
        border-radius: 15px;
        margin-right: 15px;
        display: inline-block;
        margin-top: 15px;
        font-size: 0.8rem;
    }

    .cs-action-head {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .cs-fu-tag {
            display: flex;
            align-items: center;
            padding: 10px 0 10px 0;
            border-top: 1px solid #ddd;
            label {
                margin: 0;
            }
            input {
                margin: 0 10px 0 0;
            }
            &:nth-child(1) {
                border-top: 0;
            }
            &:hover {
                background: #f9f8f8;
            }
        }
    }
    .fu-large.fu-card {

        .open-contact-buttons {
            text-align: right;
        }
        .card-back {
            margin-bottom: 30px;
        }
        .card-front {
        
            border-radius: 15px;
            overflow: hidden;
            border: 1px solid #a7a7a7;

            display: flex;

            margin-bottom:30px;

            .fu-image {
                -ms-flex: 0 0 33%;
                flex: 0 0 33%;
                max-width: 33%;
                position: relative;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    position: absolute;
                }
                &:after {
                    content: '';
                    display: block;
                    padding-bottom: 100%;
                }
            }
            .text-area {
                padding: 15px;
                -ms-flex: 0 0 67%;
                flex: 0 0 67%;
                max-width: 67%;
                .cs-image-container{
                    position: relative;
                    width: 180px;
                }
                h4{
                    margin:30px 0;
                }
                .fu-text{
                    margin-bottom:30px;
                    display: block;

                }
                
            }
        }
    }
    .fu-small.fu-card {

        border-radius: 15px;
        border: 1px solid #ddd;
        padding: 15px;
        height: calc(100% - 30px);
        overflow:hidden;
        background: #fbfcff;
        -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
        -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
        margin-bottom: 30px;


        .cs-image-container {
            margin: -15px -15px 15px -15px;
        }

        &>div{
            margin-top: 15px;
        }

        .open-contact-buttons{
            .btn{
                border: none;
                background: #feb22e;
                background: linear-gradient(70deg, #feb22e 0%, #ffe3c2 170%);
                border-radius: 20px;
                color: white;
                padding: 10px 15px;
                border-radius: 30rem;
                text-decoration: none;
                text-align: center;
                cursor: pointer;
                &:hover{
                    background: #ffca7b;
                    background: radial-gradient(circle, #ffca7b 0%, #ffca7b 100%);
                }
                &.btn-primary:focus, &.btn-primary.focus {
                    box-shadow: none;
                }
            }
        }

    }

</style>
